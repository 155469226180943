import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import SmarttagControl from '@sportnet/ui/SmarttagsControl/SmarttagControl/redux-form';

import TagmanagerConnector from '@sportnet/tagmanager-connector';

const MemoizedSmarttagsField = ({ appSpace, accessToken, appId }) => {
  return (
    <TagmanagerConnector
      appspace={appSpace}
      accessToken={accessToken}
      appId={appId}
    >
      {(getSmarttagsKeys, getSmarttagsValues, getSmarttagsByFts) => (
        <Field
          getSmarttagsValues={getSmarttagsValues}
          getSmarttagsKeys={getSmarttagsKeys}
          getSmarttagsByFts={getSmarttagsByFts}
          name="smarttags"
          component={SmarttagControl}
        />
      )}
    </TagmanagerConnector>
  );
};

MemoizedSmarttagsField.propTypes = {
  appSpace: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
};

export default React.memo(MemoizedSmarttagsField);

import 'normalize.css';
import { Provider } from 'react-redux';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import { createRoot } from 'react-dom/client';
import Api from './Api';
import Authorization from './containers/Authorization';
import CoreApi from './CoreApi';
import Login from './containers/Login';
import React from 'react';
import baseTheme from './theme/base';
import config from './config';
import reducer from './reducer';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import thunkMiddleware from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reduxMiddleware = [thunkMiddleware];

Api.setBaseUrl(process.env.REACT_APP_API_BASE_URL);
CoreApi.setBaseUrl(process.env.REACT_APP_SPORTNET_API_BASE_URL);

const GlobalStyle = createGlobalStyle`
  html, body {
    font-size: 16px;
    font-family: Roboto, 'sans-serif';
    height: 100%;
    background-color: #fff;
  }
  #root {
    height: 100%;
  }
  * {
    box-sizing: border-box;
  }
  [type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
    appearance: button;
  }
  .react-datepicker__time-container {
    width: 75px !important;
  }
`;

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  height: 100%;
  color: ${(props) => props.theme.textColor};
`;

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...reduxMiddleware)),
);

const element = document.getElementById('root');
const root = createRoot(element);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={baseTheme}>
      <GlobalStyle />

      <Wrapper>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to={config.URL_PREFIX} />
            </Route>
            <Route exact path={config.URL_PREFIX} component={Login} />
            <Route
              path={`${config.URL_PREFIX}/:appId/:appSpace`}
              component={Authorization}
            />
          </Switch>
        </Router>
      </Wrapper>
    </ThemeProvider>
  </Provider>,
);

import { __, getBaseUrl } from '../../utilities/common';
import { useHistory } from 'react-router-dom';
import Button from '@sportnet/ui/Button';
import FormGroup from '@sportnet/ui/FormGroup';
import Input from '@sportnet/ui/Input';
import Label from '@sportnet/ui/Label/Label';
import React from 'react';
import styled from 'styled-components';

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

const Form = styled.form`
  text-align: center;
`;

export const Login = () => {
  const history = useHistory();

  const [loginData, setLoginData] = React.useState({
    appId: '',
    appSpace: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (loginData.appId && loginData.appSpace) {
      const loginUrl = `${getBaseUrl(loginData.appId, loginData.appSpace)}`;
      history.push(loginUrl);
    }
  };

  return (
    <Center>
      <Form onSubmit={handleFormSubmit}>
        <FormGroup>
          <Label required>App Id</Label>
          <Input
            name="appId"
            placeholder="APP ID"
            onChange={handleChange}
            value={loginData.appId}
          />
        </FormGroup>
        <FormGroup>
          <Label required>AppSpace</Label>
          <Input
            name="appSpace"
            placeholder="APP SPACE"
            value={loginData.appSpace}
            onChange={handleChange}
          />
        </FormGroup>
        <Button
          primary
          type="submit"
          block
          disabled={!loginData.appId || !loginData.appSpace}
        >
          {__('Vstúpiť')}
        </Button>
      </Form>
    </Center>
  );
};

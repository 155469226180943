const namespace = 'App';

export const ADD_FILES_FOR_UPLOAD = `${namespace}/ADD_FILES_FOR_UPLOAD`;
export const UPLOAD_FILE_REQUEST = `${namespace}/UPLOAD_FILE_REQUEST`;
export const UPLOAD_FILE_FAILURE = `${namespace}/UPLOAD_FILE_FAILURE`;
export const UPLOAD_FILE_SUCCESS = `${namespace}/UPLOAD_FILE_SUCCESS`;
export const UPDATE_SETTING = `${namespace}/UPDATE_SETTING`;
export const LOAD_AVAILABLE_SMARTTAGS_SUCCESS = `${namespace}/LOAD_AVAILABLE_SMARTTAGS_SUCCESS`;
export const LOAD_USER_SUCCESS = `${namespace}/LOAD_USER_SUCCESS`;

export const FETCH_APPSPACEUSER_REQUEST = `${namespace}/FETCH_APPSPACEUSER_REQUEST`;
export const FETCH_APPSPACEUSER_SUCCESS = `${namespace}/FETCH_APPSPACEUSER_SUCCESS`;
export const FETCH_APPSPACEUSER_FAILURE = `${namespace}/FETCH_APPSPACEUSER_FAILURE`;

export const SET_ACCESS_TOKEN = `${namespace}/SET_ACCESS_TOKEN`;
export const SET_APP_ID = `${namespace}/SET_APP_ID`;
export const SET_APP_SPACE = `${namespace}/SET_APP_SPACE`;

export const FETCH_APPINFO_REQUEST = `${namespace}/FETCH_APPINFO_REQUEST`;
export const FETCH_APPINFO_SUCCESS = `${namespace}/FETCH_APPINFO_SUCCESS`;
export const FETCH_APPINFO_FAILURE = `${namespace}/FETCH_APPINFO_FAILURE`;

export const FETCH_CHILD_APPINFO_REQUEST = `${namespace}/FETCH_CHILD_APPINFO_REQUEST`;
export const FETCH_CHILD_APPINFO_SUCCESS = `${namespace}/FETCH_CHILD_APPINFO_SUCCESS`;
export const FETCH_CHILD_APPINFO_FAILURE = `${namespace}/FETCH_CHILD_APPINFO_FAILURE`;

export const FETCH_MEAPPSPACES_REQUEST = `${namespace}/FETCH_MEAPPSPACES_REQUEST`;
export const FETCH_MEAPPSPACES_SUCCESS = `${namespace}/FETCH_MEAPPSPACES_SUCCESS`;
export const FETCH_MEAPPSPACES_FAILURE = `${namespace}/FETCH_MEAPPSPACES_FAILURE`;

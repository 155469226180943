import config from '../config';

export const __ = (toTranslate) => {
  return toTranslate;
};

export const paramsToObject = (params) => {
  const query = params.substr(1);
  const result = {};
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const MEDIA_MANAGER_DEFAULT_IMAGES_URL = process.env
  .REACT_APP_IMAGES_BASE_URL
  ? process.env.REACT_APP_IMAGES_BASE_URL
  : 'https://mediamanager.ws/images';

export const getBaseUrl = (
  appId,
  appSpace,
  opts = { keepAppSpacePattern: false, keepApIdPattern: false },
) => {
  let result = config.APPSPACE_URL;

  if (!opts.keepApIdPattern) {
    result = result.replace('{{appId}}', appId);
  }

  if (!opts.keepAppSpacePattern) {
    result = result.replace('{{appSpace}}', appSpace);
  }

  return result;
};

export const filesWithExirationExists = (files) => {
  return files.some((f) => !!f.expiresAt);
};

export const formatMessage = (instanceId, type, payload) => ({
  instanceId,
  type,
  payload,
});

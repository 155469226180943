import * as AppActions from '../App/actions';
import * as actions from './actions';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { LIST_LIMIT, LIST_NAME } from './constants';
import { __ } from '../../utilities/common';
import {
  allFilesInSelectionSelector,
  filesSelector,
  selectedFilesIdsSelector,
} from './selectors';
import { connect } from 'react-redux';
import { currentFolderIdSelector } from '../FolderTree/selectors';
import {
  fileSelectionSelector,
  instanceIdSelector,
  uploadingFilesUploadingStatusSelector,
} from '../App/selectors';
import { getListTotal, isCommiting } from '@sportnet/redux-list';
import { rem } from 'polished';
import Button from '@sportnet/ui/Button';
import Checkbox from '@sportnet/ui/Checkbox';
import Label from '../../components/Label';
import MediaManagerAppContext from '../../context/MediaManagerAppContext';
import Paginator from '@sportnet/ui/Paginator';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import UploadStatus from './UploadStatus';
import styled from 'styled-components';

const UploadInput = styled.input`
  display: none;
`;

const StyledUploadStatus = styled(UploadStatus)`
  margin-right: ${rem(10)};
`;

const ContextBarComponent = (props) => {
  const {
    files,
    selectedFilesIds,
    uploadStatus,
    selectedFiles,
    activeFolderId,
    query,
    setQuery,
    total,
    isFetching,
    addFilesToSelection,
    emptyFileSelection,
    searchFiles,
    uploadFiles,
    addFilesForUpload,
    callBackhandleOpen,
    onClickSelectedFilesButton,
    instanceId,
    fileSelection,
  } = props;
  const { onSelectFiles } = React.useContext(MediaManagerAppContext);

  const uploadInputEl = useRef(null);
  const numberOfSelectedFiles = Object.keys(selectedFilesIds).length;

  function handleSelectAll(ev) {
    if (ev.target.checked) {
      addFilesToSelection(files.map((idx) => idx._id));
    } else {
      emptyFileSelection();
    }
  }

  function handleSelectFiles() {
    onSelectFiles(selectedFiles);
  }

  function handleClickUploadButton() {
    uploadInputEl.current.value = '';
    uploadInputEl.current.click();
  }

  async function handleChangeUpload(e) {
    e.preventDefault();

    const droppedFiles = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    const filesForUpdate = [];
    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles[i];
      filesForUpdate.push(file);
    }

    addFilesForUpload(filesForUpdate, activeFolderId);
    await uploadFiles(filesForUpdate);
    searchFiles();
  }

  return (
    <ContextBar>
      <ContextBarItem>
        <Paginator
          offset={query.offset}
          limit={LIST_LIMIT}
          total={total}
          onChangeOffset={(e) =>
            setQuery({
              offset: e,
            })
          }
          loading={isFetching}
        />
      </ContextBarItem>
      <ContextBarItem>
        <Label>
          <Checkbox
            checked={files.length > 0 && numberOfSelectedFiles === files.length}
            onChange={handleSelectAll}
          />
          {__('Označiť všetko')}
        </Label>
      </ContextBarItem>
      <ContextBarItem>
        <UploadInput
          id="upload-input"
          ref={uploadInputEl}
          type="file"
          multiple
          onChange={handleChangeUpload}
        />
      </ContextBarItem>
      <ContextBarItem>
        <Button
          basic
          primary
          disabled={uploadStatus !== 1 && uploadStatus !== null}
          onClick={handleClickUploadButton}
        >
          {__('Nahrať súbor')}
        </Button>
      </ContextBarItem>
      <ContextBarItem>
        <Button
          icon="pencil"
          basic
          primary
          disabled={numberOfSelectedFiles === 0}
          onClick={callBackhandleOpen}
        >
          {__('Upraviť')}
        </Button>
      </ContextBarItem>
      <ContextBarItem>
        <Button
          icon="check"
          primary
          disabled={numberOfSelectedFiles === 0}
          onClick={onClickSelectedFilesButton}
        >
          {__('Vybrané súbory')} ({numberOfSelectedFiles})
        </Button>
      </ContextBarItem>
      <ContextBarSpacer />
      {instanceId && fileSelection && (
        <ContextBarItem>
          <StyledUploadStatus />
          <Button
            primary
            disabled={numberOfSelectedFiles === 0}
            onClick={handleSelectFiles}
          >
            {`${__('Použiť súbory')} ${numberOfSelectedFiles}`}
          </Button>
        </ContextBarItem>
      )}
    </ContextBar>
  );
};

ContextBarComponent.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({ _id: PropTypes.string.isRequired }),
  ).isRequired,
  uploadStatus: PropTypes.number,
  isFetching: PropTypes.bool,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  callBackhandleOpen: PropTypes.func.isRequired,
  query: PropTypes.shape().isRequired,
  searchFiles: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  addFilesForUpload: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
  addFilesToSelection: PropTypes.func.isRequired,
  emptyFileSelection: PropTypes.func.isRequired,
  selectedFilesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeFolderId: PropTypes.string,
  total: PropTypes.number,
  onClickSelectedFilesButton: PropTypes.func.isRequired,
  fileSelection: PropTypes.string.isRequired,
};

ContextBarComponent.defaultProps = {
  uploadStatus: null,
  activeFolderId: null,
  isFetching: true,
  total: null,
};

const mapDispatchToProps = {
  addFilesToSelection: actions.addFilesToSelection,
  deleteFiles: actions.deleteFiles,
  emptyFileSelection: actions.emptyFileSelection,
  searchFiles: actions.searchFiles,
  addFilesForUpload: AppActions.addFilesForUpload,
  uploadFiles: AppActions.uploadFiles,
};

const mapStateToProps = (state) => ({
  isFetching: isCommiting(LIST_NAME)(state),
  total: getListTotal(LIST_NAME)(state),
  files: filesSelector(state),
  selectedFilesIds: selectedFilesIdsSelector(state),
  selectedFiles: allFilesInSelectionSelector(state),
  uploadStatus: uploadingFilesUploadingStatusSelector(state),
  activeFolderId: currentFolderIdSelector(state),
  instanceId: instanceIdSelector(state),
  fileSelection: fileSelectionSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContextBarComponent);

import { createSelector } from 'reselect';
import { getProp } from '@sportnet/utilities';

const uploadDomainSelector = (state) => state.upload;
const settingsDomainSelector = (state) => state.settings;
const userDomainSelector = (state) => state.user;

export const uploadingDomainSelector = createSelector(
  uploadDomainSelector,
  (domain) => {
    return domain.uploading;
  },
);

export const uploadingFilesSelector = createSelector(
  uploadingDomainSelector,
  (domain) => {
    return getProp(domain, ['files'], []);
  },
);

export const uploadingFilesNotStartedSelector = createSelector(
  uploadingFilesSelector,
  (files) => {
    return files.filter(
      (file) => !file.done && !file.isUploading && file.error === null,
    );
  },
);

export const uploadingFilesFinishedSelector = createSelector(
  uploadingFilesSelector,
  (files) => {
    return files
      .filter((file) => file.done && !file.isUploading && file.error === null)
      .reverse();
  },
);

export const uploadingFilesFinishedIdsMapSelector = createSelector(
  uploadingFilesFinishedSelector,
  (files) =>
    files.reduce((prev, file) => {
      return {
        ...prev,
        [getProp(file, ['uploadedFileInfo', '_id'], null)]: true,
      };
    }, {}),
);

export const uploadingFilesRestSelector = createSelector(
  uploadingFilesSelector,
  (files) => {
    return files.filter(
      (file) => file.isUploading && !file.done && file.error === null,
    ).length;
  },
);

export const uploadingFilesUploadingStatusSelector = createSelector(
  uploadingFilesRestSelector,
  uploadingFilesSelector,
  (numUploading, all) => {
    if (all.length === 0) {
      return null;
    }
    return 1 - numUploading / all.length;
  },
);

export const accessTokenSelector = createSelector(
  settingsDomainSelector,
  (substate) => substate.accessToken,
);

export const appIdSelector = createSelector(
  settingsDomainSelector,
  (substate) => substate.appId,
);

export const childAppIdSelector = createSelector(
  (state) => state,
  (substate) =>
    substate.childAppInfo && substate.childAppInfo.data
      ? substate.childAppInfo.data
      : undefined,
);

export const appSpaceSelector = createSelector(
  settingsDomainSelector,
  (substate) => substate.appSpace,
);

export const smarttagsSelector = createSelector(
  settingsDomainSelector,
  (substate) => substate.smarttags,
);

export const loggedUserSelector = createSelector(
  userDomainSelector,
  (substate) => substate,
);

export const appsSelector = createSelector(
  settingsDomainSelector,
  (substate) => substate.apps,
);

export const appSpacesSelector = createSelector(
  settingsDomainSelector,
  (substate) =>
    Array.isArray(substate.appSpaces)
      ? substate.appSpaces.map((appSpace) => appSpace.org_profile)
      : [],
);

export const instanceIdSelector = createSelector(
  settingsDomainSelector,
  (substate) => substate.instanceId,
);

export const fileSelectionSelector = createSelector(
  settingsDomainSelector,
  (substate) => substate.fileSelection,
);

import {
  ADD_FILES_FOR_UPLOAD,
  FETCH_APPINFO_FAILURE,
  FETCH_APPINFO_REQUEST,
  FETCH_APPINFO_SUCCESS,
  FETCH_APPSPACEUSER_FAILURE,
  FETCH_APPSPACEUSER_REQUEST,
  FETCH_APPSPACEUSER_SUCCESS,
  FETCH_CHILD_APPINFO_FAILURE,
  FETCH_CHILD_APPINFO_REQUEST,
  FETCH_CHILD_APPINFO_SUCCESS,
  FETCH_MEAPPSPACES_FAILURE,
  FETCH_MEAPPSPACES_REQUEST,
  FETCH_MEAPPSPACES_SUCCESS,
  LOAD_USER_SUCCESS,
  UPDATE_SETTING,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from './constants';
import { __ } from '../../utilities/common';
import {
  appIdSelector,
  appSpaceSelector,
  uploadingFilesNotStartedSelector,
} from './selectors';
import { getBaseUrl } from '../../utilities/common';
import Api from '../../Api';
import CoreApi from '../../CoreApi';

/**
 *
 * @param {File[]} files
 * @param {string} directoryId Null if, no directory is specified.
 */
export const addFilesForUpload = (files, directoryId = null) => ({
  type: ADD_FILES_FOR_UPLOAD,
  payload: {
    files,
    destinationDirectoryId: directoryId,
  },
});

/**
 *
 * @param {File} file
 * @param {string} directoryId Null if, no directory is specified.
 */
export const uploadFileRequest = (file) => ({
  type: UPLOAD_FILE_REQUEST,
  payload: {
    file,
  },
});

/**
 *
 * @param {File} file
 * @param {*} error
 */
export const uploadFileFailure = (file, error) => ({
  type: UPLOAD_FILE_FAILURE,
  payload: {
    file,
    error,
  },
});

/**
 *
 * @param {File} file
 */
export const uploadFileSuccess = (file, uploadedFileInfo) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: {
    file,
    uploadedFileInfo,
  },
});

export const uploadFiles = () => {
  return async (dispatch, getState) => {
    const files = uploadingFilesNotStartedSelector(getState());
    for await (const file of files) {
      dispatch(uploadFileRequest(file));
      try {
        let uploadedFileInfo;
        const appId = appIdSelector(getState());
        const appSpace = appSpaceSelector(getState());
        const data = {
          upload: file.file,
        };
        if (file.destinationDirectoryId) {
          data.destination = file.destinationDirectoryId;
        }
        if (appSpace) {
          uploadedFileInfo = await Api.uploadFile(appId, appSpace, {
            body: data,
          });
        } else {
          uploadedFileInfo = await Api.uploadUserFile({
            body: { ...data, appId },
          });
        }
        dispatch(uploadFileSuccess(file, uploadedFileInfo));
      } catch (e) {
        dispatch(uploadFileFailure(file));
      }
    }
    return true;
  };
};

export const setAccessToken = (accessToken) => ({
  type: UPDATE_SETTING,
  payload: {
    setting: 'accessToken',
    value: accessToken,
  },
});

export const setInstanceId = (instanceId) => ({
  type: UPDATE_SETTING,
  payload: {
    setting: 'instanceId',
    value: instanceId,
  },
});

export const setFileSelection = (fileSelection) => ({
  type: UPDATE_SETTING,
  payload: {
    setting: 'fileSelection',
    value: fileSelection,
  },
});

export const setAppId = (appId) => ({
  type: UPDATE_SETTING,
  payload: {
    setting: 'appId',
    value: appId,
  },
});

export const setAppSpace = (appSpace) => ({
  type: UPDATE_SETTING,
  payload: {
    setting: 'appSpace',
    value: appSpace,
  },
});

export const setAppspaces = (appSpaces) => ({
  type: UPDATE_SETTING,
  payload: {
    setting: 'appSpaces',
    value: appSpaces,
  },
});

export const setApps = (apps) => ({
  type: UPDATE_SETTING,
  payload: {
    setting: 'apps',
    value: apps,
  },
});

export const loadUserSuccess = (user) => ({
  type: LOAD_USER_SUCCESS,
  payload: {
    user,
  },
});

export const loadUser = () => {
  return async (dispatch) => {
    try {
      const response = await CoreApi.me();
      dispatch(loadUserSuccess(response));
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchRequestAppInfo = () => ({
  type: FETCH_APPINFO_REQUEST,
  payload: {},
});

export const fetchSuccessAppInfo = (appInfo) => ({
  type: FETCH_APPINFO_SUCCESS,
  payload: {
    appInfo,
  },
});

export const fetchFailureAppInfo = (error) => ({
  type: FETCH_APPINFO_FAILURE,
  payload: {
    error,
  },
});

export const fetchRequestChildAppInfo = () => ({
  type: FETCH_CHILD_APPINFO_REQUEST,
  payload: {},
});

export const fetchSuccessChildAppInfo = (appInfo) => ({
  type: FETCH_CHILD_APPINFO_SUCCESS,
  payload: {
    appInfo,
  },
});

export const fetchFailureChildAppInfo = (error) => ({
  type: FETCH_CHILD_APPINFO_FAILURE,
  payload: {
    error,
  },
});

export const fetchAppInfo = ({ appId, instanceId, fileSelection }) => {
  return (dispatch) => {
    dispatch(fetchRequestAppInfo());
    CoreApi.getPublicApp(appId)
      .then((res) => {
        dispatch(
          fetchSuccessAppInfo({
            ...res,
            appSpaceUrlPattern: `${getBaseUrl(appId, '', {
              keepAppSpacePattern: true,
            })}${instanceId ? `?instance_id=${instanceId}` : ''}${
              fileSelection ? `&fileSelection=true` : ''
            }`,
          }),
        );
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          fetchFailureAppInfo({
            description: e.description,
            text: __('Nepodarilo sa získať informácie o aplikácii'),
          }),
        );
      });
  };
};

export const fetchChildAppInfo = ({ appId }) => {
  return (dispatch) => {
    dispatch(fetchRequestChildAppInfo());
    CoreApi.getPublicApp(appId)
      .then((res) => {
        dispatch(
          fetchSuccessChildAppInfo({
            ...res,
          }),
        );
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          fetchFailureChildAppInfo({
            description: e.description,
            text: __('Nepodarilo sa získať informácie o podaplikácii'),
          }),
        );
      });
  };
};

export const fetchRequestAppSpaceUser = () => ({
  type: FETCH_APPSPACEUSER_REQUEST,
  payload: {},
});

export const fetchSuccessAppSpaceUser = (appSpace) => {
  return {
    type: FETCH_APPSPACEUSER_SUCCESS,
    payload: {
      appSpace,
    },
  };
};

export const fetchFailureAppSpaceUser = (error) => ({
  type: FETCH_APPSPACEUSER_FAILURE,
  payload: {
    error,
  },
});

export const fetchAppSpace = (appId, appSpace) => {
  return (dispatch) => {
    dispatch(fetchRequestAppSpaceUser());
    CoreApi.meAppInfoForAppSpace(appId, appSpace)
      .then((res) => {
        dispatch(fetchSuccessAppSpaceUser(res));
      })
      .catch(async (e) => {
        dispatch(
          fetchFailureAppSpaceUser({
            ...(e.details || {}),
            text:
              (e.details || {}).userinfo ||
              __('Nepodarilo sa získať informácie o používateľovi'),
          }),
        );
      });
  };
};

export const fetchRequestMeAppSpaces = () => ({
  type: FETCH_MEAPPSPACES_REQUEST,
  payload: {},
});

export const fetchSuccessMeAppSpaces = (res) => ({
  type: FETCH_MEAPPSPACES_SUCCESS,
  payload: {
    res,
  },
});

export const fetchFailureMeAppSpaces = (error) => ({
  type: FETCH_MEAPPSPACES_FAILURE,
  payload: {
    error,
  },
});

export const fetchMeAppSpaces = ({
  appId,
  appSpace,
  instanceId,
  fileSelection,
}) => {
  return (dispatch) => {
    dispatch(fetchRequestMeAppSpaces());
    CoreApi.meAppSpaces({ expandApp: true })
      .then((res) => {
        dispatch(fetchSuccessMeAppSpaces(res));
        const newApps = (res?.apps || []).map((a) => {
          const appSpaceIds = (a.appspaces || []).map((as) => as.app_space);
          const selectedAppSpace =
            appSpaceIds.find((a) => a === appSpace) ?? appSpaceIds[0];
          return {
            ...a.app,
            appSpaceIds,
            appSpaceUrlPattern: `${getBaseUrl(a.app_id, selectedAppSpace)}${
              instanceId ? `?instance_id=${instanceId}` : ''
            }${fileSelection ? `&fileSelection=true` : ''}`,
          };
        });

        dispatch(setApps(newApps));
        const appObj = (res.apps || []).find((a) => a.app_id === appId);
        if (appObj) {
          const newAppSpaces = appObj.appspaces || [];
          dispatch(setAppspaces(newAppSpaces));
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          fetchFailureMeAppSpaces({
            description: e.description,
            text: __('Nepodarilo sa získať informácie o aplikáciách užívatela'),
          }),
        );
      });
  };
};

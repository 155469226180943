import { rem } from 'polished';
import Overlay from './Overlay';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  flex: 1 0 0;
  display: flex;
  height: calc(100vh - ${rem(48)});
`;

const Dropzone = ({ children, onDropFiles, isActive }) => {
  const [draggedOver, setDraggedOver] = React.useState(false);

  const handleDragOver = (e) => {
    if (isActive) {
      e.preventDefault();
      e.stopPropagation();
      e.dataTransfer.dropEffect = 'copy';
      setDraggedOver(true);
    }
  };

  const handleDragLeave = (e) => {
    if (isActive) {
      e.preventDefault();
      setDraggedOver(false);
    }
  };

  const handleDrop = (e) => {
    if (isActive) {
      e.preventDefault();
      e.stopPropagation();

      const files = Array.from(e.dataTransfer.files);

      onDropFiles(files);
      setDraggedOver(false);
    }
  };

  return (
    <Wrapper
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      data-testid="MM_App_DropZone"
    >
      {children}
      {draggedOver && <Overlay />}
    </Wrapper>
  );
};

Dropzone.propTypes = {
  children: PropTypes.node,
  onDropFiles: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

Dropzone.defaultProps = {
  children: null,
};

export default Dropzone;

import * as actions from './actions';
import { LIST_NAME, QUERY_HOC_CONFIG } from './constants';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { __ } from '../../utilities/common';
import {
  accessTokenSelector,
  appIdSelector,
  appSpaceSelector,
  uploadingFilesFinishedIdsMapSelector,
} from '../App/selectors';
import { bytes2Size, getProp } from '@sportnet/utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  currentFolderIdSelector,
  foldersByIdSelector,
} from '../FolderTree/selectors';
import {
  filesSelector,
  searchFilterSelector,
  selectedFilesByIdSelector,
} from './selectors';
import {
  isCommiting,
  setParams as setReduxListParams,
} from '@sportnet/redux-list';
import {
  isoDate2Distance,
  isoDate2FormattedDate,
  mimeType2type,
} from '../../utilities/formatting';
import { rem } from 'polished';
import Button from '@sportnet/ui/Button';
import Checkbox from '@sportnet/ui/Checkbox';
import ContentLoader from '../../components/ContentLoader';
import ContextBar from './ContextBar';
import Cookies from 'js-cookie';
import FilePreview from './FilePreview';
import FileSelectionModal from './FileSelectionModal';
import Filter from '@sportnet/ui/Filter';
import HeaderBar from '@sportnet/ui/HeaderBar';
import Icon from '@sportnet/ui/Icon';
import NotFound from '@sportnet/ui/NotFound';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import SidebarDetail from '../SidebarDetail';
import TagmanagerConnector from '@sportnet/tagmanager-connector';
import styled, { css, withTheme } from 'styled-components';
import useQuery from '@sportnet/query-hoc/useQuery';

const Wrapper = styled.div`
  background: #fff;
`;

const StyledCheckbox = styled(Checkbox)`
  top: ${rem(-1)};
`;

const StyledTr = styled(Tr)`
  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.table.activeRow} !important;
  background-color: ${({ theme }) => theme.table.activeRowBg} !important;}
    `}
`;

const StyledFilePreview = styled(FilePreview)`
  margin-right: ${rem(16)};
`;

const Filename = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const ButtonSegment = styled(Button)`
  padding: 6px 8px;
  font-size: 80%;
  min-height: auto;
  ${({ $position }) => {
    if ($position === 'left') {
      return css`
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 0px;
      `;
    } else if ($position === 'right') {
      return css`
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      `;
    } else if ($position === 'middle') {
      return css`
        border-radius: 0px;
        border-right: 0px;
      `;
    }
  }}
`;

const Expander = styled(ButtonSegment)`
  font-size: ${({ $size }) => {
    switch ($size) {
      case 'S':
        return '12px';
      case 'M':
        return '16px';
      case 'L':
        return '22px';
      default:
        return '10px';
    }
  }};
`;

const Files = ({
  files,
  selectedFilesById,
  isFetching,
  foldersById,
  addFilesToSelection,
  removeFilesFromSelection,
  searchFiles,
  setParams,
  setSearchFilter,
  searchFilter,
  dragFileEnded,
  dragFileStarted,
  activeFolderId,
  appId,
  appSpace,
  accessToken,
  appSpaceUser,
}) => {
  const loadDebounce = React.useRef();
  const prevQuery = React.useRef({});
  const [, updateState] = React.useState();
  const [isOpened, setIsOpen] = React.useState(null);
  const [isFileSelectionModalOpened, setIsFileSelectionModalOpened] =
    React.useState(false);
  const forceUpdate = useCallback(() => updateState({}), []);
  const { query, setQuery } = useQuery(
    window.location.search,
    (serializedQuery) => {
      window.history.pushState('', '', serializedQuery);
      forceUpdate();
    },
    QUERY_HOC_CONFIG,
  );
  const numberOfSelectedFiles = Object.keys(selectedFilesById).length;
  const isFileSelected = (file) => file._id in selectedFilesById;

  const [thumbnailsSize, setThumbnailsSize] = useState(
    Cookies.get('thumbnailsSize') || 'S',
  );

  useEffect(() => {
    Cookies.set('thumbnailsSize', thumbnailsSize, { path: '/', expires: 180 });
  }, [thumbnailsSize]);

  useEffect(() => {
    setIsOpen(Object.keys(selectedFilesById).length > 0);
  }, [selectedFilesById]);

  const [displayFilesCategory, setDisplayFilesCategory] = useState(
    Cookies.get('displayFilesCategory') || 'all',
  );

  useEffect(() => {
    Cookies.set('displayFilesCategory', displayFilesCategory, {
      path: '/',
      expires: 180,
      secure: process.env.NODE_ENV === 'development' ? false : true,
      partitioned: true,
    });
  }, [displayFilesCategory]);

  function handleClickName(e) {
    e.stopPropagation();
  }

  const handleEditMenu = () => {
    setIsOpen(!isOpened);
  };
  function handleClickOnFile(file) {
    if (isFileSelected(file)) {
      removeFilesFromSelection([file._id]);
    } else {
      addFilesToSelection([file._id]);
    }
  }

  function renderPathOfFile(file) {
    return `/${getProp(file, ['parents'], [])
      .map((parentId, idx, arr) => {
        const id = arr[arr.length - 1 - idx];
        if (id in foldersById) {
          return foldersById[id].title;
        }
        return '';
      })
      .join('/')}`;
  }

  // reset offset to 0, when filters change
  useEffect(() => {
    if (query.offset === prevQuery.current.offset) {
      setQuery({ ...query, offset: 0 });
    }
    prevQuery.current = query;
  }, [query, activeFolderId, searchFilter, setQuery]);

  // Reload items when query changes
  useEffect(() => {
    const nextParams = { ...query, ...searchFilter, displayFilesCategory };
    if (activeFolderId) {
      nextParams.directoryId = activeFolderId;
    }
    setParams({
      listName: LIST_NAME,
      parameters: nextParams,
    });
    clearTimeout(loadDebounce.current);
    loadDebounce.current = window.setTimeout(() => {
      searchFiles();
    }, 300);
  }, [
    setParams,
    query,
    activeFolderId,
    searchFilter,
    searchFiles,
    displayFilesCategory,
  ]);

  // Reload on last refresh click
  const forceRefresh = () => {
    const nextParams = { ...query, ...searchFilter, displayFilesCategory };
    if (activeFolderId) {
      nextParams.directoryId = activeFolderId;
    }
    setParams({
      listName: LIST_NAME,
      parameters: nextParams,
    });
    searchFiles();
  };

  const handleSelectionModalClose = () => {
    setIsFileSelectionModalOpened(false);
  };

  const handleClickSelectedFilesButton = () => {
    setIsFileSelectionModalOpened(true);
  };

  function render() {
    if (isFetching && files.length === 0) {
      return <ContentLoader />;
    }

    if (files.length === 0) {
      return (
        <NotFound
          icon="search-document"
          title={__('Nenašiel sa žiaden súbor')}
        />
      );
    }

    const isExpiresAt = !!files && !!files.find((f) => f.expiresAt);

    return (
      <Wrapper>
        <Table>
          <Thead>
            <Tr>
              <Th>{__('')}</Th>
              <Th>{__('Názov')}</Th>
              <Th>{__('Dátum vytvorenia')}</Th>
              {isExpiresAt && <Th>{__('Dátum platnosti')}</Th>}
              <Th>{__('Cesta')}</Th>
              <Th>{__('Veľkosť')}</Th>
              <Th>{__('Typ')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {files &&
              files.map((file) => {
                return (
                  <StyledTr
                    key={file._id}
                    onClick={() => {
                      handleClickOnFile(file);
                    }}
                    $active={isFileSelected(file)}
                    draggable
                    onDragEnd={() => {
                      dragFileEnded();
                    }}
                    onDragStart={(ev) => {
                      dragFileStarted();
                      ev.dataTransfer.setData(
                        'text',
                        JSON.stringify({
                          ...file,
                        }),
                      );
                    }}
                  >
                    <Td>
                      <span onClick={(e) => e.stopPropagation()}>
                        <StyledCheckbox
                          checked={isFileSelected(file)}
                          value={file._id}
                          onChange={() => {
                            handleClickOnFile(file);
                          }}
                        />
                      </span>
                    </Td>
                    <Td>
                      <Filename>
                        <StyledFilePreview
                          file={file}
                          thumbnailsSize={thumbnailsSize}
                        />
                        <a
                          href={file.public_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={handleClickName}
                        >
                          {file.name}
                        </a>
                      </Filename>
                    </Td>
                    <Td>{isoDate2FormattedDate(file.created_date)}</Td>
                    {isExpiresAt && (
                      <Td style={{ color: 'red' }}>
                        {file.expiresAt
                          ? isoDate2Distance(file.expiresAt)
                          : null}
                      </Td>
                    )}
                    <Td>{renderPathOfFile(file)}</Td>
                    <Td>{bytes2Size(file.size)}</Td>
                    <Td>{mimeType2type(file.mimetype)}</Td>
                  </StyledTr>
                );
              })}
          </Tbody>
        </Table>
      </Wrapper>
    );
  }

  const filter = (
    <TagmanagerConnector
      appId={appId}
      appspace={appSpace}
      accessToken={accessToken}
    >
      {(getSmarttagsKeys, getSmarttagsValues, getSmarttagsByFts) => (
        <Filter
          filters={[
            {
              type: 'query',
              name: 'query',
              placeholder: __('Zadajte hľadaný výraz'),
            },
            {
              type: 'smarttag',
              label: 'Smart:tag',
              name: 'smarttag',
              getSmarttagsKeys,
              getSmarttagsValues,
              getSmarttagsByFts,
            },
          ]}
          onChange={setSearchFilter}
          value={searchFilter}
        />
      )}
    </TagmanagerConnector>
  );

  const contextBar = (
    <ContextBar
      setQuery={setQuery}
      query={query}
      callBackhandleOpen={handleEditMenu}
      onClickSelectedFilesButton={handleClickSelectedFilesButton}
    />
  );

  const handleSidebarFileSelectionTitleClick = () => {
    setIsFileSelectionModalOpened((prev) => !prev);
  };

  return (
    <ScrollLayout
      topFixed={
        <>
          <HeaderBar>
            <HeaderBar.Header>
              {__('Súbory')}{' '}
              <strong>
                {getProp(appSpaceUser || {}, ['org_profile', 'name'])}
              </strong>
            </HeaderBar.Header>
            &nbsp;
            <ButtonSegment
              basic={displayFilesCategory !== 'all'}
              loading={displayFilesCategory === 'all' && isFetching}
              primary
              onClick={() => {
                setDisplayFilesCategory('all');
              }}
              $position="left"
            >
              {__('všetky')}
            </ButtonSegment>
            <ButtonSegment
              basic={displayFilesCategory !== 'permanent'}
              loading={displayFilesCategory === 'permanent' && isFetching}
              primary
              onClick={() => {
                setDisplayFilesCategory('permanent');
              }}
              $position="middle"
            >
              {__('len trvalé')}
            </ButtonSegment>
            <ButtonSegment
              basic={displayFilesCategory !== 'temporary'}
              loading={displayFilesCategory === 'temporary' && isFetching}
              primary
              onClick={() => {
                setDisplayFilesCategory('temporary');
              }}
              $position="right"
            >
              {__('len dočasné')}
            </ButtonSegment>
            &nbsp;
            <ButtonSegment size="s" loading={isFetching} onClick={forceRefresh}>
              <Icon name="refresh" />
            </ButtonSegment>
            <HeaderBar.Spacer />
            {['S', 'M', 'L'].map((size, i, all) => (
              <Expander
                key={`expander-${size}`}
                $size={size}
                basic={size !== thumbnailsSize}
                primary
                onClick={() => setThumbnailsSize(size)}
                $position={
                  i === 0 ? 'left' : i === all.length - 1 ? 'right' : 'middle'
                }
              >
                A
              </Expander>
            ))}
          </HeaderBar>
          {filter}
        </>
      }
      bottomFixed={contextBar}
    >
      {render()}
      {numberOfSelectedFiles > 0 && (
        <SidebarDetail
          opened={isOpened}
          callBackhandleOpen={handleEditMenu}
          onFileSelectionTitleClick={handleSidebarFileSelectionTitleClick}
        />
      )}

      <FileSelectionModal
        isOpened={isFileSelectionModalOpened}
        onClose={handleSelectionModalClose}
      />
    </ScrollLayout>
  );
};

Files.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      created_date: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      mimetype: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeFolderId: PropTypes.number,
  foldersById: PropTypes.shape({}).isRequired,
  selectedFilesById: PropTypes.shape({}).isRequired,
  addFilesToSelection: PropTypes.func.isRequired,
  removeFilesFromSelection: PropTypes.func.isRequired,
  searchFiles: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  searchFilter: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool,
  dragFileEnded: PropTypes.func.isRequired,
  dragFileStarted: PropTypes.func.isRequired,
};

Files.defaultProps = { isFetching: false, activeFolderId: null };

const mapDispatchToProps = {
  addFilesToSelection: actions.addFilesToSelection,
  dragFileEnded: actions.dragFileEnded,
  dragFileStarted: actions.dragFileStarted,
  removeFilesFromSelection: actions.removeFilesFromSelection,
  searchFiles: actions.searchFiles,
  setParams: setReduxListParams,
  setSearchFilter: actions.setSearchFilter,
};

const mapStateToProps = (state) => ({
  files: filesSelector(state),
  selectedFilesById: selectedFilesByIdSelector(state),
  uploadedFilesIdsMap: uploadingFilesFinishedIdsMapSelector(state),
  foldersById: foldersByIdSelector(state),
  searchFilter: searchFilterSelector(state),
  isFetching: isCommiting(LIST_NAME)(state),
  activeFolderId: currentFolderIdSelector(state),
  appId: appIdSelector(state),
  appSpace: appSpaceSelector(state),
  accessToken: accessTokenSelector(state),
  appInfo: state.appInfo.data,
  appSpaceUser: state.appSpaceUser.data,
});

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
)(Files);

import { rem } from 'polished';
import Skeleton from '@sportnet/ui/Skeleton';
import styled from 'styled-components';

const BRADIUS = 4;
const HIEGHT = 14;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(15)} ${rem(10)} ${rem(10)} ${rem(10)};
`;

const LoaderItem = styled.div`
  height: ${rem(40)};
  width: 100%;
  flex: 0 0 auto;
`;

const FolderTreeLoader = () => {
  return (
    <Wrapper>
      <LoaderItem>
        <Skeleton width="85%" height={HIEGHT} borderRadius={BRADIUS} />
      </LoaderItem>
      <LoaderItem>
        <Skeleton width="70%" height={HIEGHT} borderRadius={BRADIUS} />
      </LoaderItem>
      <LoaderItem>
        <Skeleton width="90%" height={HIEGHT} borderRadius={BRADIUS} />
      </LoaderItem>
      <LoaderItem>
        <Skeleton width="50%" height={HIEGHT} borderRadius={BRADIUS} />
      </LoaderItem>
      <LoaderItem>
        <Skeleton width="85%" height={HIEGHT} borderRadius={BRADIUS} />
      </LoaderItem>
      <LoaderItem>
        <Skeleton width="65%" height={HIEGHT} borderRadius={BRADIUS} />
      </LoaderItem>
      <LoaderItem>
        <Skeleton width="95%" height={HIEGHT} borderRadius={BRADIUS} />
      </LoaderItem>
      <LoaderItem>
        <Skeleton width="50%" height={HIEGHT} borderRadius={BRADIUS} />
      </LoaderItem>
    </Wrapper>
  );
};

export default FolderTreeLoader;

import * as actions from './actions';
import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/Table';
import { __ } from '../../utilities/common';
import { allFilesInSelectionSelector } from './selectors';
import { bytes2Size, getProp } from '@sportnet/utilities';
import { connect } from 'react-redux';
import { foldersByIdSelector } from '../FolderTree/selectors';
import {
  isoDate2Distance,
  isoDate2FormattedDate,
  mimeType2type,
} from '../../utilities/formatting';
import { rem } from 'polished';
import Button from '@sportnet/ui/Button';
import FilePreview from './FilePreview';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div``;

const StyledModalContent = styled(ModalContent)`
  overflow-y: auto;
  flex: 1 1 auto;
`;

const StyledTr = styled(Tr)`
  ${(props) =>
    props.active &&
    css`
      color: ${({ theme }) => theme.table.activeRow} !important;
  background-color: ${({ theme }) => theme.table.activeRowBg} !important;}
    `}
`;

const Filename = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const StyledFilePreview = styled(FilePreview)`
  margin-right: ${rem(16)};
`;

const FileSelectionModal = ({
  isOpened,
  onClose,
  selectedFiles,
  foldersById,
  removeFilesFromSelection,
  emptyFileSelection,
}) => {
  const [fileToBeRemoved, setFileToBeRemoved] = React.useState(null);
  const [isResetSelectionModalOpened, setIsResetSelectionModalOpened] =
    React.useState();

  const isExpiresAt =
    !!selectedFiles && !!selectedFiles.find((f) => f.expiresAt);

  const renderPathOfFile = (file) => {
    return `/${getProp(file, ['parents'], [])
      .map((parentId, idx, arr) => {
        const id = arr[arr.length - 1 - idx];
        if (id in foldersById) {
          return foldersById[id].title;
        }
        return '';
      })
      .join('/')}`;
  };

  const handleRemoveFromSelectionClick = () => {
    removeFilesFromSelection([fileToBeRemoved._id]);
    setFileToBeRemoved(null);
  };

  const handleCloseConfirmModal = () => {
    setFileToBeRemoved(null);
  };

  const handleCloseResetSelectionModal = () => {
    setIsResetSelectionModalOpened(false);
  };

  const handleResetSelectionClick = () => {
    emptyFileSelection();
    setIsResetSelectionModalOpened(false);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpened} handleClose={onClose} size="full">
        {isOpened && (
          <StyledModalContent>
            <Wrapper>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{__('Názov')}</Th>
                    <Th>{__('Dátum vytvorenia')}</Th>
                    {isExpiresAt && <Th>{__('Dátum platnosti')}</Th>}
                    <Th>{__('Cesta')}</Th>
                    <Th>{__('Veľkosť')}</Th>
                    <Th>{__('Typ')}</Th>
                    <Th>{__('Odstrániť z výberu')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {selectedFiles.length > 0 &&
                    selectedFiles.map((file) => {
                      return (
                        <StyledTr key={file._id}>
                          <Td>
                            <Filename>
                              <StyledFilePreview
                                file={file}
                                thumbnailsSize={'S'}
                              />
                              <a
                                href={file.public_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                            </Filename>
                          </Td>
                          <Td>{isoDate2FormattedDate(file.created_date)}</Td>
                          {isExpiresAt && (
                            <Td style={{ color: 'red' }}>
                              {file.expiresAt
                                ? isoDate2Distance(file.expiresAt)
                                : null}
                            </Td>
                          )}
                          <Td>{renderPathOfFile(file)}</Td>
                          <Td>{bytes2Size(file.size)}</Td>
                          <Td>{mimeType2type(file.mimetype)}</Td>
                          <Td>
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                setFileToBeRemoved(file);
                              }}
                              icon="trash"
                              danger
                            ></Button>
                          </Td>
                        </StyledTr>
                      );
                    })}
                </Tbody>
              </Table>
            </Wrapper>
          </StyledModalContent>
        )}
        <ModalActions>
          <Button
            basic
            onClick={() => {
              setIsResetSelectionModalOpened(true);
            }}
            danger
            icon="trash"
          >
            {__('Vymazať výber')}
          </Button>
          <Button basic onClick={onClose}>
            {__('Zatvoriť')}
          </Button>
        </ModalActions>
      </Modal>
      <Modal
        isOpen={!!fileToBeRemoved}
        handleClose={handleCloseConfirmModal}
        size="xs"
      >
        <ModalContent>
          Odsrániť súbor "{fileToBeRemoved?.name}" z výberu?
        </ModalContent>
        <ModalActions>
          <Button basic onClick={handleCloseConfirmModal}>
            {__('Zrušiť')}
          </Button>
          <Button
            basic
            onClick={handleRemoveFromSelectionClick}
            danger
            type="button"
            disabled={!fileToBeRemoved}
          >
            {__('Áno')}
          </Button>
        </ModalActions>
      </Modal>
      <Modal
        isOpen={isResetSelectionModalOpened}
        handleClose={handleCloseResetSelectionModal}
        size="xs"
        forceZindex={111}
      >
        <ModalContent>Vymazať celý výber?</ModalContent>
        <ModalActions>
          <Button basic onClick={handleCloseResetSelectionModal}>
            {__('Zrušiť')}
          </Button>
          <Button
            basic
            onClick={handleResetSelectionClick}
            danger
            type="button"
            disabled={!isResetSelectionModalOpened}
          >
            {__('Áno')}
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

FileSelectionModal.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  foldersById: PropTypes.shape({}).isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emptyFileSelection: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  removeFilesFromSelection: actions.removeFilesFromSelection,
  emptyFileSelection: actions.emptyFileSelection,
};

const mapStateToProps = (state) => ({
  selectedFiles: allFilesInSelectionSelector(state),
  foldersById: foldersByIdSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileSelectionModal);

import {
  FETCH_FOLDER_TREE_FAILURE,
  FETCH_FOLDER_TREE_REQUEST,
  FETCH_FOLDER_TREE_SUCCESS,
  SET_ACTIVE_FOLDER,
  SET_FOLDER_TREE,
} from './constants';
import { appIdSelector, appSpaceSelector } from '../App/selectors';
import { foldersByIdSelector, foldersSelector } from './selectors';
import Api from '../../Api';
import Cookies from 'js-cookie';

const EXPANDED_FOLDER_IDS_COOKIES_KEY = 'expanded_folder_ids';

export const normalizeTree = (folders, expandedFolderIds = []) => {
  const traverseChildren = (children) => {
    if (!children) {
      return [];
    }
    return children.map((folder) => {
      return {
        _id: folder._id,
        title: folder.name,
        expanded: expandedFolderIds.includes(folder._id),
        children: traverseChildren(folder.children),
        parents: folder.parents || [],
      };
    });
  };
  return traverseChildren(folders);
};

export const requestFolderTree = () => ({
  type: FETCH_FOLDER_TREE_REQUEST,
});
export const receiveFolderTreeSuccess = () => ({
  type: FETCH_FOLDER_TREE_SUCCESS,
});
export const receiveFolderTreeFailure = (error) => ({
  type: FETCH_FOLDER_TREE_FAILURE,
  payload: {
    error,
  },
});

export const setFolderTree = (folders) => {
  const expandedIds = [];
  const traverseChildren = (children) => {
    if (!children) {
      return;
    }
    children.forEach((folder) => {
      if (folder.expanded) {
        expandedIds.push(folder._id);
      }
      traverseChildren(folder.children);
    });
  };
  traverseChildren(folders);
  Cookies.set(EXPANDED_FOLDER_IDS_COOKIES_KEY, expandedIds.join(','), {
    secure: process.env.NODE_ENV === 'development' ? false : true,
    partitioned: true,
  });
  return {
    type: SET_FOLDER_TREE,
    payload: {
      folders,
    },
  };
};

export const fetchFolderTree = () => {
  return async (dispatch, getState) => {
    dispatch(requestFolderTree());
    try {
      const appSpace = appSpaceSelector(getState());
      let response;
      if (appSpace) {
        response = await Api.getFoldersTree(
          appIdSelector(getState()),
          appSpace,
        );
      } else {
        response = await Api.getUserFoldersTree();
      }

      const folders = foldersSelector(getState());
      let savedExpandedFolderIds = [];
      if (folders.length === 0) {
        // este nemam nic nacitane - pravdepodobne ide o prve nacitanie
        const ids = (Cookies.get(EXPANDED_FOLDER_IDS_COOKIES_KEY) || '')
          .split(',')
          .filter((a) => a);
        if (Array.isArray(ids) && ids.length > 0) {
          savedExpandedFolderIds = ids;
        }
      }

      dispatch(receiveFolderTreeSuccess());
      const foldersById = foldersByIdSelector(getState());
      const expandedFolderIds = Object.keys(foldersById)
        .filter((folderId) => foldersById[folderId].expanded)
        .concat(savedExpandedFolderIds);
      dispatch(
        setFolderTree(normalizeTree(response.folders, expandedFolderIds)),
      );
    } catch (e) {
      dispatch(receiveFolderTreeFailure(e));
    }
  };
};

export const setActiveFolder = (id) => ({
  type: SET_ACTIVE_FOLDER,
  payload: {
    activeId: id,
  },
});

export const renameFolder = (id, name) => {
  return async (dispatch, getState) => {
    const appSpace = appSpaceSelector(getState());
    const data = {
      body: {
        name,
      },
    };
    if (appSpace) {
      return Api.editFolderById(appIdSelector(getState()), appSpace, id, data);
    }
    return Api.editUserFolderById(id, data);
  };
};

export const removeFolder = (id) => {
  return async (dispatch, getState) => {
    const appSpace = appSpaceSelector(getState());
    if (appSpace) {
      return Api.deleteFolderById(appIdSelector(getState()), appSpace, id);
    }
    return Api.deleteUserFolderById(id);
  };
};

export const createFolder = (parent, name) => {
  return async (dispatch, getState) => {
    const appSpace = appSpaceSelector(getState());
    const data = {
      body: {
        name,
        parent,
      },
    };
    if (appSpace) {
      await Api.createFolder(appIdSelector(getState()), appSpace, data);
    } else {
      await Api.createUserFolder(data);
    }
  };
};

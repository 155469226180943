import { Redirect, Route, Switch } from 'react-router-dom';
import {
  filesWithExirationExists,
  formatMessage,
  getBaseUrl,
} from '../../utilities/common';
import {
  setAccessToken,
  setAppId,
  setAppSpace,
  setFileSelection,
  setInstanceId,
} from '../App/actions';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Api from '../../Api';
import App from '../App';
import AuthProvider from '@sportnet/auth-react/AuthProvider';
import CoreApi from '../../CoreApi';
import Loader from '@sportnet/ui/Loader';
import MediaManagerAppContext from '../../context/MediaManagerAppContext';
import React from 'react';
import config from '../../config';
import styled from 'styled-components';
import withPopups from '../../components/WithPopups';

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

const DEFAULT_Q_PARAMS = {
  instanceId: '',
  useLastMM: false,
};

const parseMMSearchParams = (stringParams) => {
  if (stringParams) {
    const parsedParams = new URLSearchParams(stringParams);
    return {
      instanceId: parsedParams.get('instance_id') ?? '',
      useLastMM: (parsedParams.get('useLastMM') || '') === 'true',
      fileSelection: parsedParams.get('fileSelection') === 'true',
    };
  }
  return DEFAULT_Q_PARAMS;
};

const getAuthDataFromLocation = (location) => {
  const { pathname, search, hash } = location;
  const splitted = [];
  let query = '';
  if (hash === '#_auth') {
    const searchParams = new URLSearchParams(search);
    const stateParam = searchParams.get('state') ?? '';
    const [url, queryParams] = stateParam.split('?');
    query = queryParams;
    splitted.push(...url.split('/').filter(Boolean));
  } else {
    splitted.push(...pathname.split('/').filter(Boolean));
    query = search;
  }
  return {
    urlPrefix: splitted[0] ?? '',
    appId: splitted[1] ?? '',
    appSpace: splitted[2] ?? '',
    ...parseMMSearchParams(query),
  };
};

const _Authorization = ({ confirm }) => {
  const location = useLocation();

  // data su bud z URL (ked som prihlaseny), alebo zo state parametra auth redirektu
  const { appId, appSpace, instanceId, useLastMM, fileSelection } =
    getAuthDataFromLocation(location);

  const history = useHistory();
  const dispatch = useDispatch();
  const [authorized, setAuthorized] = React.useState(false);

  const handleAuthorize = ({ accessToken }) => {
    if (accessToken) {
      Api.setToken(accessToken);
      CoreApi.setToken(accessToken);
      setAuthorized(true);
      dispatch(setAccessToken(accessToken));
      dispatch(setAppId(appId));
      dispatch(setAppSpace(appSpace));
      dispatch(setInstanceId(instanceId));
      dispatch(setFileSelection(fileSelection));
      localStorage.setItem(`mm_last_contentspace`, `${appId}:${appSpace}`);
      history.push(`${getBaseUrl(appId, appSpace)}/files${location.search}`);
    }
  };

  const mediaManagerAppContextValue = React.useMemo(() => {
    const handleSelectFiles = async (files) => {
      let canContinue = true;
      if (filesWithExirationExists(files)) {
        canContinue = await confirm(
          'Niektoré zvolené súbory majú dočasnú platnosť. Po jej uplynutí budú automaticky zmazané. Naozaj ich chcete použiť v obsahu?',
        );
      }

      if (
        !!instanceId &&
        canContinue &&
        window.parent &&
        typeof window.parent.postMessage === 'function'
      ) {
        window.parent.postMessage(
          [formatMessage(instanceId, 'FILES_SELECTED', files)],
          '*',
        );
      }
    };

    const handleClose = () => {
      if (instanceId) {
        window.parent.postMessage([formatMessage(instanceId, 'CLOSE')], '*');
      } else {
        window.location.href = config.URL_PREFIX;
      }
    };

    return {
      onClose: handleClose,
      onSelectFiles: handleSelectFiles,
      isInIframe: window.self !== window.top,
    };
  }, [confirm, instanceId]);

  if (useLastMM) {
    const lastMM = (localStorage.getItem('mm_last_contentspace') ?? '').split(
      ':',
    );
    if (lastMM.length === 2) {
      const [lastAppId, lastAppSpace] = lastMM;
      if (appId !== lastAppId || appSpace !== lastAppSpace) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('useLastMM');
        return (
          <Redirect
            to={`${getBaseUrl(lastAppId, lastAppSpace)}${
              searchParams.size ? `?${searchParams.toString()}` : ''
            }`}
          />
        );
      }
    }
  }

  return appId && appSpace ? (
    <AuthProvider
      app={appId}
      ppo={appSpace}
      onAuthorize={handleAuthorize}
      routerType="hash"
    >
      {!authorized ? (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      ) : (
        <MediaManagerAppContext.Provider value={mediaManagerAppContextValue}>
          <Switch>
            <Route
              path={`${config.URL_PREFIX}/:appId/:appSpace/files`}
              component={App}
            />
          </Switch>
        </MediaManagerAppContext.Provider>
      )}
    </AuthProvider>
  ) : (
    <>
      Chyba parsovania prihlasovacích údajov!{' '}
      {JSON.stringify({ appId, appSpace })}
    </>
  );
};

export const Authorization = withPopups(_Authorization);

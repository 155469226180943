import {
  appInfoReducer,
  appSpaceUserReducer,
  childAppInfoReducer,
  meAppSpacesReducer,
  settingsReducer,
  uploadReducer,
  userReducer,
} from './containers/App/reducer';
import { combineReducers } from 'redux';
import {
  fileListReducer,
  filesDraggingReducer,
  entityReducer as filesReducer,
  searchFilterReducer,
} from './containers/Files/reducer';
import { reducer as formReducer } from 'redux-form';
import { reducer as listReducer } from '@sportnet/redux-list';
import folderTreeReducer from './containers/FolderTree/reducer';
import toasts from './containers/Toasts/reducer';

export default combineReducers({
  appInfo: appInfoReducer,
  childAppInfo: childAppInfoReducer,
  appSpaceUser: appSpaceUserReducer,
  entities: combineReducers({
    files: filesReducer,
  }),
  files: fileListReducer,
  folderTree: folderTreeReducer,
  upload: uploadReducer,
  form: formReducer,
  toasts,
  list: listReducer,
  settings: settingsReducer,
  user: userReducer,
  filesDragging: filesDraggingReducer,
  searchFilter: searchFilterReducer,
  meAppSpaces: meAppSpacesReducer,
});

import { LIST_NAME } from '../Files/constants';
import { __ } from '../../utilities/common';
import {
  addFilesForUpload,
  fetchAppInfo,
  fetchAppSpace,
  fetchChildAppInfo,
  fetchMeAppSpaces,
  loadUser,
  uploadFiles,
} from './actions';
import {
  appIdSelector,
  appSpaceSelector,
  appSpacesSelector,
  appsSelector,
  childAppIdSelector,
  fileSelectionSelector,
  instanceIdSelector,
  loggedUserSelector,
} from './selectors';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import {
  currentFolderIdSelector,
  pathToActiveFolderSelector,
} from '../FolderTree/selectors';
import { filesDraggingSelector } from '../Files/selectors';
import { initialize } from '@sportnet/redux-list';
import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import { searchFiles } from '../Files/actions';
import Api from '../../Api';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import ContentLoader from '../../components/ContentLoader';
import Dropzone from '../../components/Dropzone';
import Files from '../Files';
import FolderTree from '../FolderTree';
import MediaManagerAppContext from '../../context/MediaManagerAppContext';
import PropTypes from 'prop-types';
import React from 'react';
import TheLayout from '@sportnet/ui/TheLayout';
import config from '../../config';
import styled, { css } from 'styled-components';
import withPopups from '../../components/WithPopups';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  flex: 1 0 0;
  > div {
    width: 100%;
  }
  ${({ theme, isInIframe = false }) =>
    isInIframe &&
    css`
      border: 1px solid ${theme.color.danger};
      border-radius: ${rem(4)};
      overflow: hidden;
      ${mb('s')} {
        border: 2px solid ${theme.color.danger};
        border-radius: ${rem(8)};
      }
    `}
`;

const mapStateToProps = (state) => {
  return {
    activeFolderId: currentFolderIdSelector(state),
    user: loggedUserSelector(state),
    pathToActiveFolder: pathToActiveFolderSelector(state),
    filesDragging: filesDraggingSelector(state),
    appSpace: appSpaceSelector(state),
    appId: appIdSelector(state),
    appInfo: state.appInfo.data,
    childApp: childAppIdSelector(state),
    appSpaceUser:
      state.appSpaceUser.data && state.appSpaceUser.data.org_profile
        ? state.appSpaceUser.data.org_profile
        : undefined,
    apps: appsSelector(state),
    appSpaces: appSpacesSelector(state),
    instanceId: instanceIdSelector(state),
    fileSelection: fileSelectionSelector(state),
  };
};

const App = ({
  alert,
  appId,
  appSpace,
  filesDragging,
  pathToActiveFolder,
  activeFolderId,
  appSpaceUser,
  appInfo,
  childApp,
  apps,
  appSpaces,
  instanceId,
  fileSelection,
}) => {
  const { onClose, isInIframe } = React.useContext(MediaManagerAppContext);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          offset: 0,
        },
      }),
    );
    dispatch(loadUser());
    dispatch(fetchAppInfo({ appId, instanceId, fileSelection }));
    dispatch(fetchChildAppInfo({ appId: config.CHILD_APP_ID }));
    dispatch(fetchAppSpace(appId, appSpace));
    dispatch(fetchMeAppSpaces({ appId, appSpace, instanceId, fileSelection }));
  }, [dispatch, appId, appSpace, instanceId, fileSelection]);

  React.useEffect(() => {
    Api.onResponseUnauthorized = () => {
      alert(__(`Na tento obsah nemáte právo!`)).then(() => {
        window.location.href = process.env.REACT_APP_BASE_URL;
      });
    };

    Api.onResponseForbiden = () => {
      alert(__('Na tento obsah nemáte právo'));
    };
  }, []);

  const handleDropFiles = async (files) => {
    dispatch(addFilesForUpload(files, activeFolderId));
    await dispatch(uploadFiles(files));
    dispatch(searchFiles());
  };

  if (typeof appSpace !== 'string') {
    return <ContentLoader />;
  }

  return (
    <Wrapper isInIframe={isInIframe}>
      <TheLayout
        app={appInfo}
        title="Media Manager vľ"
        customNavigation={<FolderTree />}
        onClose={onClose}
        apps={apps}
        appSpace={appSpaceUser}
        appSpaces={appSpaces}
        childApp={childApp}
      >
        <AppContext
          breadcrumbs={
            pathToActiveFolder.length > 0
              ? [
                  {
                    name: pathToActiveFolder.pop().title,
                  },
                ]
              : [
                  {
                    name: __('Všetky súbory'),
                  },
                ]
          }
        />
        <Dropzone isActive={!filesDragging} onDropFiles={handleDropFiles}>
          <Files />
        </Dropzone>
      </TheLayout>
    </Wrapper>
  );
};

App.propTypes = {
  alert: PropTypes.func.isRequired,
  user: PropTypes.shape({
    display_name: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
  filesDragging: PropTypes.bool.isRequired,
  pathToActiveFolder: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string }),
  ).isRequired,
  activeFolderId: PropTypes.number,
};

App.defaultProps = {
  user: null,
  activeFolderId: null,
};

export default compose(withPopups, connect(mapStateToProps))(App);

import {
  ADD_FILES_FOR_UPLOAD,
  FETCH_APPINFO_FAILURE,
  FETCH_APPINFO_REQUEST,
  FETCH_APPINFO_SUCCESS,
  FETCH_APPSPACEUSER_FAILURE,
  FETCH_APPSPACEUSER_REQUEST,
  FETCH_APPSPACEUSER_SUCCESS,
  FETCH_CHILD_APPINFO_FAILURE,
  FETCH_CHILD_APPINFO_REQUEST,
  FETCH_CHILD_APPINFO_SUCCESS,
  FETCH_MEAPPSPACES_FAILURE,
  FETCH_MEAPPSPACES_REQUEST,
  FETCH_MEAPPSPACES_SUCCESS,
  LOAD_AVAILABLE_SMARTTAGS_SUCCESS,
  LOAD_USER_SUCCESS,
  SET_ACCESS_TOKEN,
  SET_APP_ID,
  SET_APP_SPACE,
  UPDATE_SETTING,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from './constants';
import { combineReducers } from 'redux';

const uploadingInitialState = {
  files: [],
};

const uploadingReducer = (state = uploadingInitialState, action) => {
  switch (action.type) {
    case ADD_FILES_FOR_UPLOAD:
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload.files.map((file) => {
            return {
              file,
              isUploading: false,
              done: false,
              error: null,
              destinationDirectoryId: action.payload.destinationDirectoryId,
            };
          }),
        ],
      };
    case UPLOAD_FILE_REQUEST: {
      const idx = state.files.findIndex(
        (file) => file.file === action.payload.file.file,
      );
      if (idx !== -1) {
        const file = { ...state.files[idx] };
        file.isUploading = true;
        return {
          ...state,
          files: [
            ...state.files.slice(0, idx),
            file,
            ...state.files.slice(idx + 1),
          ],
        };
      }
      return state;
    }
    case UPLOAD_FILE_SUCCESS: {
      const idx = state.files.findIndex(
        (file) => file.file === action.payload.file.file,
      );
      if (idx !== -1) {
        const file = { ...state.files[idx] };
        file.isUploading = false;
        file.done = true;
        file.uploadedFileInfo = action.payload.uploadedFileInfo;
        return {
          ...state,
          files: [
            ...state.files.slice(0, idx),
            file,
            ...state.files.slice(idx + 1),
          ],
        };
      }
      return state;
    }
    default:
      return state;
  }
};

const uploadReducer = combineReducers({
  uploading: uploadingReducer,
});

const settingsInitialState = {
  accessToken: null,
  appId: null,
  appSpace: null,
  smarttags: [],
};

const settingsReducer = (state = settingsInitialState, action) => {
  switch (action.type) {
    case UPDATE_SETTING:
      return {
        ...state,
        [action.payload.setting]: action.payload.value,
      };
    case LOAD_AVAILABLE_SMARTTAGS_SUCCESS:
      return {
        ...state,
        smarttags: action.payload.smarttags,
      };
    default:
      return state;
  }
};

const userInitialState = null;

const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case LOAD_USER_SUCCESS:
      return action.payload.user;
    default:
      return state;
  }
};

const appInfoInitialState = {
  data: {},
  isFetching: true,
  error: null,
};

const appInfoReducer = (state = appInfoInitialState, action) => {
  switch (action.type) {
    case FETCH_APPINFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_APPINFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.appInfo,
      };
    case FETCH_APPINFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case SET_APP_ID:
      return {
        ...state,
        appId: action.payload.appId,
      };
    case SET_APP_SPACE:
      return {
        ...state,
        appSpace: action.payload.appSpace,
      };
    default:
      return state;
  }
};

const childAppInfoInitialState = {
  data: {},
  isFetching: true,
  error: null,
};

const childAppInfoReducer = (state = childAppInfoInitialState, action) => {
  switch (action.type) {
    case FETCH_CHILD_APPINFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CHILD_APPINFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.appInfo,
      };
    case FETCH_CHILD_APPINFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

const appSpaceUserInitialState = {
  data: {},
  isFetching: true,
  error: null,
};

const appSpaceUserReducer = (state = appSpaceUserInitialState, action) => {
  switch (action.type) {
    case FETCH_APPSPACEUSER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_APPSPACEUSER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.appSpace,
      };
    case FETCH_APPSPACEUSER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

const meAppspacesInitialState = {
  data: {},
  isFetching: true,
  error: null,
};

const meAppSpacesReducer = (state = meAppspacesInitialState, action) => {
  switch (action.type) {
    case FETCH_MEAPPSPACES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_MEAPPSPACES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.res,
      };
    case FETCH_MEAPPSPACES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export {
  uploadReducer,
  settingsReducer,
  userReducer,
  appInfoReducer,
  childAppInfoReducer,
  appSpaceUserReducer,
  meAppSpacesReducer,
};
